import * as Types from '../generated/graphql';

import { gql } from '@apollo/client/core/core.cjs';
import * as Apollo from '@apollo/client/core/core.cjs';
const defaultOptions = {} as const;
export type SearchQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
  workspaceId: Types.Scalars['uuid']['input'];
}>;


export type SearchQuery = { __typename?: 'query_root', promotion: Array<{ __typename?: 'promotion', id: any, description: string, image?: string | null, price?: any | null, name: string }>, membership: Array<{ __typename?: 'membership', id: any, name: string, description?: string | null, image?: string | null, price: number }>, package: Array<{ __typename?: 'package', id: any, name: string, description?: string | null, image?: string | null, price: number }>, service: Array<{ __typename?: 'service', id: any, name: string, image?: string | null, description?: string | null, price: number, taxRate?: any | null, viewOnlyInStore?: boolean | null }> };


export const SearchDocument = gql`
    query Search($query: String!, $workspaceId: uuid!) {
  promotion(
    where: {isActive: {_eq: true}, isArchived: {_eq: false}, workspace: {id: {_eq: $workspaceId}}, startsAt: {_lte: "now()"}, endsAt: {_gte: "now()"}, title: {_ilike: $query}}
  ) {
    id
    name: title
    description
    image
    price
  }
  membership(
    where: {availableInStore: {_eq: true}, workspace: {id: {_eq: $workspaceId}}, _or: [{name: {_ilike: $query}}, {description: {_ilike: $query}}]}
  ) {
    id
    name
    description
    image
    price
  }
  package(
    where: {availableInStore: {_eq: true}, isActive: {_eq: true}, workspace: {id: {_eq: $workspaceId}}, _or: [{name: {_ilike: $query}}, {description: {_ilike: $query}}]}
  ) {
    id
    name
    description
    image
    price
  }
  service(
    where: {isProduct: {_eq: true}, availableInStore: {_eq: true}, workspace: {id: {_eq: $workspaceId}}, _or: [{name: {_ilike: $query}}, {description: {_ilike: $query}}], active: {_eq: true}, archived: {_eq: false}}
  ) {
    id
    name
    image
    description
    price
    taxRate
    viewOnlyInStore
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export function useSearchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchSuspenseQueryHookResult = ReturnType<typeof useSearchSuspenseQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;