import * as Types from '../generated/graphql';

import { gql } from '@apollo/client/core/core.cjs';
import * as Apollo from '@apollo/client/core/core.cjs';
const defaultOptions = {} as const;
export type ListPromotionsQueryVariables = Types.Exact<{
  slug: Types.Scalars['String']['input'];
}>;


export type ListPromotionsQuery = { __typename?: 'query_root', promotion: Array<{ __typename?: 'promotion', id: any, description: string, image?: string | null, price?: any | null, isShippable: boolean, shippingFee?: any | null, displayOrder?: number | null, name: string }> };


export const ListPromotionsDocument = gql`
    query ListPromotions($slug: String!) {
  promotion(
    where: {isActive: {_eq: true}, isArchived: {_eq: false}, workspace: {slug: {_eq: $slug}}, _or: [{allowPreview: {_eq: true}}, {startsAt: {_lte: "now()"}}], endsAt: {_gte: "now()"}}
    order_by: {displayOrder: asc}
  ) {
    id
    name: title
    description
    image
    price
    isShippable
    shippingFee
    displayOrder
  }
}
    `;

/**
 * __useListPromotionsQuery__
 *
 * To run a query within a React component, call `useListPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPromotionsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useListPromotionsQuery(baseOptions: Apollo.QueryHookOptions<ListPromotionsQuery, ListPromotionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPromotionsQuery, ListPromotionsQueryVariables>(ListPromotionsDocument, options);
      }
export function useListPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPromotionsQuery, ListPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPromotionsQuery, ListPromotionsQueryVariables>(ListPromotionsDocument, options);
        }
export function useListPromotionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListPromotionsQuery, ListPromotionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPromotionsQuery, ListPromotionsQueryVariables>(ListPromotionsDocument, options);
        }
export type ListPromotionsQueryHookResult = ReturnType<typeof useListPromotionsQuery>;
export type ListPromotionsLazyQueryHookResult = ReturnType<typeof useListPromotionsLazyQuery>;
export type ListPromotionsSuspenseQueryHookResult = ReturnType<typeof useListPromotionsSuspenseQuery>;
export type ListPromotionsQueryResult = Apollo.QueryResult<ListPromotionsQuery, ListPromotionsQueryVariables>;