/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useEffect, useState } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import client from '../../libs/graphql/client';
import { SearchDocument } from '@/libs/graphql/queries/search.generated';
import { formatCentsToDollars, serializeStringToSlug } from '@/libs/utils';
import { ListPromotionsDocument } from '@/libs/graphql/queries/listPromotions.generated';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Search({
  workspaceId,
  workspaceSlug,
}: {
  workspaceId: string;
  workspaceSlug: string;
}) {
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (query !== '') {
        client
          .query({
            query: SearchDocument,
            variables: {
              query: `%${query}%`,
              workspaceId,
            },
          })
          .then((result) => {
            console.log(result, 'RESULT');

            setSearchResults([
              ...result.data.promotion?.map((p) => ({
                ...p,
                type: 'promotions',
              })),
              ...result.data.service?.map((p) => ({ ...p, type: 'products' })),
              ...result.data.package?.map((p) => ({ ...p, type: 'packages' })),
              ...result.data.membership?.map((p) => ({
                ...p,
                type: 'memberships',
              })),
            ]);
          });
      }
    }, 300); // Debounce time of 300ms

    return () => clearTimeout(handler);
  }, [query, workspaceId]);

  useEffect(() => {
    client
      .query({
        query: ListPromotionsDocument,
        variables: {
          slug: workspaceSlug,
        },
      })
      .then((result) => {
        setSearchResults(result.data.promotion);
      });
  }, []);

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md  px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
      >
        <MagnifyingGlassIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        <span className="hidden lg:block">Search</span>
      </button>
      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setQuery('')}
        appear
      >
        <Dialog className="relative z-20" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="mt-20 fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox
                  onChange={(result) =>
                    (window.location = `/${result.type}/${serializeStringToSlug(
                      result.name
                    )}/${result.id}`)
                  }
                >
                  {({ activeOption }) => (
                    <>
                      <div className="relative">
                        <MagnifyingGlassIcon
                          className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <Combobox.Input
                          className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                          placeholder="Search..."
                          value={query}
                          onChange={(event) => setQuery(event.target.value)}
                        />
                      </div>

                      {(query === '' || searchResults?.length > 0) && (
                        <Combobox.Options
                          as="div"
                          static
                          hold
                          className="flex transform-gpu divide-x divide-gray-100"
                        >
                          <div
                            className={classNames(
                              'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                              activeOption && 'sm:h-96'
                            )}
                          >
                            {query === '' && (
                              <h2 className="mb-4 mt-2 text-xs font-semibold text-gray-500">
                                Recent promotions
                              </h2>
                            )}
                            <div className="-mx-2 text-sm text-gray-700">
                              {searchResults.map((result) => (
                                <Combobox.Option
                                  as="div"
                                  key={result.id}
                                  value={result}
                                  className={({ active }) =>
                                    classNames(
                                      'flex cursor-default select-none items-center rounded-md p-2',
                                      active && 'bg-gray-100 text-gray-900'
                                    )
                                  }
                                >
                                  {({ active }) => (
                                    <>
                                      <img
                                        src={result.image}
                                        alt=""
                                        className="h-6 w-6 flex-none rounded-full object-cover"
                                      />
                                      <span className="ml-3 flex-auto truncate">
                                        {result.name}
                                      </span>
                                      {active && (
                                        <ChevronRightIcon
                                          className="ml-3 h-5 w-5 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))}
                            </div>
                          </div>

                          {activeOption && (
                            <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                              <div className="flex-none p-6 text-center">
                                <img
                                  src={activeOption.image}
                                  alt=""
                                  className="mx-auto h-32 w-32 rounded-md shadow-sm object-cover"
                                />
                                <h2 className="mt-3 font-semibold text-gray-900">
                                  {activeOption.name}
                                </h2>
                                <p className="text-sm leading-6 text-gray-500 truncate">
                                  {activeOption.description}
                                </p>
                              </div>
                              <div className="flex flex-auto flex-col justify-between p-6">
                                <button
                                  onClick={() => {
                                    window.location = `/${
                                      activeOption.type
                                    }/${serializeStringToSlug(
                                      activeOption.name
                                    )}/${activeOption.id}`;
                                  }}
                                  type="button"
                                  className="mt-6 w-full rounded-md bg-slate-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  View Details
                                </button>
                              </div>
                            </div>
                          )}
                        </Combobox.Options>
                      )}

                      {query !== '' && searchResults?.length === 0 && (
                        <div className="px-6 py-14 text-center text-sm sm:px-14">
                          <ShoppingBagIcon
                            className="mx-auto h-6 w-6 text-gray-400"
                            aria-hidden="true"
                          />
                          <p className="mt-4 font-semibold text-gray-900">
                            No results found
                          </p>
                          <p className="mt-2 text-gray-500">
                            Please try a different search term, or browse our
                            store's navigation menu.
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
